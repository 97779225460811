<template>
  <div>
    <v-container>
      <v-dialog
        v-model="isDialog"
        persistent
        max-width="60vw">
        <v-card
          v-show="true">
          <v-card-title>
            <span v-html="dialogHeader"></span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-container class="grey lighten-5">
                <v-row
                  align="center">
                  <v-col cols="2">
                    <v-text-field id="cmnTransNoEditedItemFyText"
                      class="cmn-trans-number-edited-item-fy-text"
                      v-model.number="newItem.fiscalYear"
                      readonly
                      label="Fiscal Year"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field id="cmnTransNoEditedItemMaxTransNo"
                      class="cmn-Trans-number-edited-item-max-trans-no"
                      v-model.number="newItem.transactionNumber"
                      readonly
                      label="Transaction Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Amount"
                      :value="newItem.amount"
                      v-model="newItem.amount"
                      readonly
                      :rules="amountValidation"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <PaymentDetails id="cmnTransNoEditedItemPaymentDetails"
                      class="cmn-Trans-number-edited-item-payment-details"
                      v-model="newItem.paymentDetails"
                      toolbarTitle="Batch Payment Details"
                      :cmnEnums="cmnEnums"
                      :amount="newItem.amount"
                      :isNewItem="isNewItem"
                      :isTotal="true"
                      @totalRemainingPaymentAmount="totalRemainingPaymentAmount"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-btn
              color="blue darken-1"
              text
              @click="canSave"
              >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
              >
              Cancel
            </v-btn>
            <v-btn
              v-if="gettingID"
              color="red darken-1"
              text
              @click="deleteAllPaymentDetails"
              >
              DELETE ALL
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import PaymentDetails from '../paymentDetails/PaymentDetails.vue';
import {
  amountFormat,
  amountToNumber,
  // extractId,
} from '../../../util/shared/vue-global';

export default {
  name: 'TrnsactionNumberEditedItem',
  components: {
    PaymentDetails,
  },
  props: {
    criteriaStateFY: {
      type: [Number, String],
    },
    selectedItemsAmount: {
      type: [Number, String],
    },
    cmnBaseCrudKey: {
      type: String,
      default: '',
    },
    cmnEnums: {
      type: Object,
      default: () => {},
    },
    closeDialogBox: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    batchPaymentDetailsEditItem: {
      type: Object,
      default: () => {},
    },
    isNewItem: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogHeader: '',
    totalRemainingPayAmt: null,
    newItem: {
      fiscalYear: null,
      transactionNumber: null,
      amount: null,
      paymentDetails: [{
        paymentType: null,
        amount: null,
      }],
    },
  }),
  created() {
    this.onLoadComponent();
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapGetters(['todaysDate']),
    isDialog: {
      get() {
        return this.closeDialogBox;
      },
      set(value) {
        this.$emit('settingDialogVal', value);
      },
    },
    amountValidation() {
      return [
        (this.isPaymentAmtMatched) || ('Mismatched Payment Amount'),
      ];
    },
    isPaymentAmtMatched() {
      return (this.newItem.paymentDetails || []).length === 0 || this.totalRemainingPayAmt === 0;
    },
    gettingID() {
      return this.batchPaymentDetailsEditItem && this.batchPaymentDetailsEditItem._id;
    },
  },
  methods: {
    ...mapActions('common/cmnTransNumber', [
      'upsrtCmnTransNum',
      'loadLastTransactionNo',
      'delCmnTransNum',
    ]),
    ...mapMutations('common/cmnTransNumber', [
      'setTransNoPolling',
      'setTransNoPulse',
    ]),
    close() {
      this.$emit('settingDialogVal', false);
    },
    buildPaymentItem(element) {
      if (element.paymentType) {
        const amount = amountToNumber(element.amount);
        const updatedElement = {
          paymentType: element.paymentType,
          amount,
        };
        if (element.paymentType === 'CASH') {
          updatedElement.cashReceived = amountToNumber(element.cashReceived);
          updatedElement.cashChange = amountToNumber(element.cashChange);
        }
        if (element.paymentType === 'CREDIT') {
          updatedElement.creditType = element.creditType;
        }
        if (element.paymentType === 'CHECK') {
          updatedElement.checkNumber = element.checkNumber;
          updatedElement.checkPaidBy = element.checkPaidBy;
        }
        if (element.paymentType === 'EFT') {
          updatedElement.eftType = element.eftType;
        }
        return { ...updatedElement };
      }
      return { ...element };
    },
    async canSave() {
      const result = this.$refs.form.validate();
      if (result) {
        const updatedItem = { ...this.newItem };
        updatedItem.amount = amountToNumber(updatedItem.amount);
        updatedItem.transactionNumber = amountToNumber(updatedItem.transactionNumber);
        if ((updatedItem.paymentDetails || []).length > 0) {
          updatedItem.paymentDetails = updatedItem.paymentDetails.map((element) => {
            const newUpdatedElement = this.buildPaymentItem(element);
            return {
              ...newUpdatedElement,
            };
          });
        }
        const response = await this.upsrtCmnTransNum({
          item: updatedItem,
          baseCrudKey: this.cmnBaseCrudKey,
        });
        this.close();
        this.$emit('transactionNumberObj', response);
        return response;
      }
      return result;
    },
    totalRemainingPaymentAmount(value) {
      this.totalRemainingPayAmt = amountToNumber(value);
      return value;
    },
    smallestTransNo() {
      let smallestTransaction;
      if (Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
        smallestTransaction = this.selectedItems[0].transactionNumber;
        for (let i = 1; i < this.selectedItems.length; i += 1) {
          if (this.selectedItems[i].transactionNumber < smallestTransaction) {
            smallestTransaction = this.selectedItems[i].transactionNumber;
          }
        }
      }
      return smallestTransaction;
    },
    async deleteAllPaymentDetails() {
      const item = {
        _id: this.gettingID,
      };
      await this.delCmnTransNum({
        item,
        baseCrudKey: this.cmnBaseCrudKey,
      });
      this.$emit('deleteBatchPaymentDetails', item);
    },
    onLoadComponent() {
      if (this.isNewItem) {
        this.dialogHeader = 'New Transaction Number';
        this.newItem.fiscalYear = this.criteriaStateFY;
        this.newItem.amount = amountFormat(amountToNumber(this.selectedItemsAmount));
        const minNumber = this.smallestTransNo();
        this.newItem.transactionNumber = minNumber;
      } else {
        this.dialogHeader = 'Edit Transaction Number';
        const abcd = this.batchPaymentDetailsEditItem;
        const copyNewItemObj = JSON.parse(JSON.stringify(abcd));
        this.newItem = copyNewItemObj;
      }
    },
  },
};

</script>
