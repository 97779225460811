/* Just for temporay purpose we put this mtg caluclator utility here,
  soon we will move it to tmc-lib */
import { amountFormat, amountToNumber } from '../../../util/shared/vue-global';
import { dateDiff } from '../../../util/shared/tmc-global';

/* Approximate (1000 * 60 * 60 * 24 * 365.25)ms = 1 year */
const oneYearInMs = 31557600000;

export const calculateTermYear = ({ dateOfMortgage, mortgageDueDate }) => {
  const { diffMs } = dateDiff(dateOfMortgage, mortgageDueDate);
  const years = Math.floor(diffMs / oneYearInMs);
  let termYear;
  if (years === 0) {
    termYear = 1;
  } else {
    termYear = years;
  }
  return termYear;
};

export const calculateRateOfTax = ({ termYear }) => {
  let rateOfTax = null;
  if (termYear >= 5) {
    rateOfTax = 0.10;
  } else if (termYear >= 4 && termYear < 5) {
    rateOfTax = 0.08;
  } else if (termYear >= 3 && termYear < 4) {
    rateOfTax = 0.06;
  } else if (termYear >= 2 && termYear < 3) {
    rateOfTax = 0.04;
  } else if (termYear < 2 && termYear > 0) {
    rateOfTax = 0.02;
  }
  return rateOfTax;
};

export const calculateMtgTax = ({ mortgageAmount, rateOfTax }) => {
  let mortgageTax = 0.0;
  if (amountToNumber(mortgageAmount) < 100 && amountToNumber(mortgageAmount) !== 0) {
    mortgageTax = 0.1;
  } else {
    const ceiledAmount = Math.ceil(amountToNumber(mortgageAmount) / 100);
    mortgageTax = amountFormat((amountToNumber(ceiledAmount) * amountToNumber(rateOfTax)));
  }
  return mortgageTax;
};

export const calculateTotalAmt = ({ mortgageTax, fee }) => amountFormat(amountToNumber(mortgageTax)
  + amountToNumber(fee));

export default {};
