var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TransactionList"},[_c('TransactionCriteria',{attrs:{"baseCrudKey":_vm.baseCrudKey},on:{"loadCRUDList":_vm.handleLoadCRUDList}}),_c('CRUDList',{ref:"crudList",attrs:{"canAdd":true,"canEdit":true,"canSave":true,"headers":_vm.headers,"showSelect":true,"showDate":true,"showTotal":true,"baseCrudKey":_vm.baseCrudKey,"createNewItem":_vm.newItemDialog,"selectedItems":_vm.selectedItems,"itemTotal":_vm.selectedItemsAmount,"defaultSortBy":_vm.sortBy,"uploadFileParams":_vm.uploadFileParams,"loadingData":_vm.gridLoading.flag,"loadingText":_vm.gridLoading.text,"toolbarTitle":"Mortgage Tax"},on:{"loadCRUDList":_vm.handleLoadCRUDList,"upsertItem":_vm.upsrtTransaction,"deleteItem":_vm.deleteTransaction,"emitSelectedItem":_vm.emitSelectedItem,"activateItem":_vm.activateTransaction},scopedSlots:_vm._u([{key:"editedItem",fn:function(ref){return [_c('TransactionEditedItem',{attrs:{"baseCrudKey":_vm.baseCrudKey,"attachmentConstants":_vm.attachmentConstants,"cmnEnums":_vm.cmnEnums},on:{"emitEditBatchPaymntItem":_vm.handleBatchPaymntDetailsEditItem,"editItem":_vm.editItem}})]}},{key:"additionalActions",fn:function(ref){return [_c('v-row',[_c('v-btn',{staticClass:"mtg-trans-print-format-btn",attrs:{"id":"mtgTransPrintFormatBtn","text":"","color":"blue darken-1"},on:{"click":function($event){return _vm.mtgPrintReceiptFromDialogBox()}}},[_vm._v(" Print Receipt")])],1),(_vm.getActiveTransID)?_c('AuditDialog',{staticClass:"mtg-trans-audit-dialog",attrs:{"identifier":_vm.getActiveTransID,"activatorCaption":"Audit History"}}):_vm._e(),_c('UploadFile',{staticClass:"mtgtrans uploadFile",attrs:{"bucketName":_vm.attachmentConstants.bucketName,"program":_vm.attachmentConstants.program,"parentObjectType":_vm.attachmentConstants.parentObjectType,"parentObjectId":_vm.getActiveTransID || '',"itemIsSaved":!_vm.isNewItem},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var activatorProps = ref.activatorProps;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"contextmenu":function($event){$event.preventDefault();return _vm.imagesRightClick(activatorProps.rightClick)},"click":function($event){return _vm.imagesLeftClick(activatorProps.leftClick)}}},[_vm._v(" "+_vm._s(activatorProps.caption)+" ")])]}}],null,true)})]}},{key:"canDeleteSlot",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isSuperAdmin)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 mtg-translist-delete-icon",attrs:{"id":"mtgTransListDeleteIcon","color":"primary","fab":"","icon":"","small":"","disabled":(_vm.selectedItems || []).length === 0},on:{"click":_vm.deleteItems}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})]}},{key:"additionalToolsAtRight",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mtg-translist-print-receipt-icon",attrs:{"id":"mtgTranListPrintReceiptBtn","depressed":"","small":"","color":"primary","disabled":_vm.printReceiptBtnValidation},on:{"click":function($event){return _vm.mtgPrintReceiptOutsideDialogBox()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]),_vm._v(" Receipt ")],1)]}}],null,true)},[_c('span',[_vm._v("Print Receipt")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})]}},{key:"bulkMarking",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.forceReloadDetails)?_c('transactionNumber',{attrs:{"cmnBaseCrudKey":_vm.baseCrudKey,"cmnEnums":_vm.cmnEnums,"closeDialogBox":_vm.closeDialogBox,"criteriaStateFY":_vm.criteriaState.fiscalYear,"selectedItemsAmount":_vm.selectedItemsAmount,"selectedItems":_vm.selectedItems,"isNewItem":_vm.isNewBatchPaymentTransaction,"batchPaymentDetailsEditItem":_vm.transNoObject},on:{"settingDialogVal":_vm.settingDialogVal,"transactionNumberObj":_vm.transactionNumberObj,"deleteBatchPaymentDetails":_vm.deleteBatchPaymentDetails}}):_vm._e(),_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","small":"","disabled":(_vm.selectedItems || []).length === 0},on:{"click":_vm.openComponent}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaBCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Batch Pay")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }