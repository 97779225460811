/*
  * This mixin is very specific for mtg program transactionList
  * It is a kind of mtg-transactionList extended file
  * Be careful before mixin it anywhere else
*/

export default {
  methods: {
    isContainingDetails(item) {
      return ((!Array.isArray(item.paymentDetails)
        || item.paymentDetails.length === 0)
        && (!(Array.isArray((item.transactionNumberObject || {}).paymentDetails))
        || ((item.transactionNumberObject || {}).paymentDetails || []).length === 0));
    },
    postProccessData(items) {
      const cssProp = ['orange', 'accent-1'];
      const updatedItems = [];
      for (let i = 0; i < items.length; i += 1) {
        const item = { ...items[i] };
        if (this.isContainingDetails(item)) {
          item.crudRowCssAppend = cssProp;
        }
        updatedItems.push(item);
      }
      return updatedItems;
    },
    async upsertBatchPaymentDetails(batchPaymentRecord) {
      // console.info('--upsertBatchPaymentDetails-batchPaymentRecord---', batchPaymentRecord);
      this.handleTransactionsAfterBatchPayment(this.selectedItems, { batchPaymentRecord, operation: 'new' });
    },
    async deleteBatchPaymentDetails(batchPaymentRecord) {
      // console.info('--batchPaymentRecord---', batchPaymentRecord);
      const mtgTransactionRecords = await this.loadMtgTransaction({
        criteria: { transactionNumberObject: batchPaymentRecord._id },
      });
      // console.info('--mtgTransactionRecords---', mtgTransactionRecords);
      this.handleTransactionsAfterBatchPayment(mtgTransactionRecords, { operation: 'delete' });
    },
    async upsertMtgTransactionAfterBatchPayment(selectedTransactions, buildUpsertItem,
      { batchPaymentRecord }) {
      for (let i = 0; i < (selectedTransactions || []).length; i += 1) {
        const selectedTransaction = { ...selectedTransactions[i] };
        const itemForUpsert = await buildUpsertItem(selectedTransaction, batchPaymentRecord);
        await this.upsertMtgTrans({
          baseCrudKey: this.baseCrudKey,
          item: itemForUpsert,
          postProcessTransaction: this.postProccessData,
        });
        // console.info('---upsertMtgTransactionAfterBatchPayment--', result);
      }
    },
    async handleBatchPaymentForDeleteOperation(item) {
      const maxTransactionNumber = await this.loadLastTransactionNo({
        fiscalYear: item.fiscalYear,
      });
      const nextTransactionNumber = maxTransactionNumber + 1;
      const itemForUpsert = {
        _id: item._id,
        transactionNumberObject: null,
        transactionNumber: nextTransactionNumber,
      };
      return itemForUpsert;
    },
    async handleBatchPaymentForNewOperation(item, batchPaymentRecord) {
      const itemForUpsert = {
        _id: item._id,
        transactionNumberObject: batchPaymentRecord._id,
        transactionNumber: batchPaymentRecord.transactionNumber,
      };
      return itemForUpsert;
    },
    async handleTransactionsAfterBatchPayment(selectedTransactions,
      { batchPaymentRecord, operation }) {
      switch (operation) {
        case 'new':
          await this.upsertMtgTransactionAfterBatchPayment(
            selectedTransactions,
            this.handleBatchPaymentForNewOperation,
            { batchPaymentRecord },
          );
          this.customCloseDialogBoxUtility();
          break;
        case 'update':
          // logic for existing batch payment details
          this.customCloseDialogBoxUtility();
          this.$nextTick(() => {
            this.handleLoadCRUDList();
          });
          break;
        case 'delete':
          await this.upsertMtgTransactionAfterBatchPayment(
            selectedTransactions,
            this.handleBatchPaymentForDeleteOperation,
            {},
          );
          this.settingDialogVal(false);
          this.customCloseDialogBoxUtility();
          break;
        default:
          break;
      }
      this.selectedItems = [];
    },
  },
};
