<template>
  <div
    class="TransactionList">
    <TransactionCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <CRUDList
      ref="crudList"
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :headers="headers"
      :showSelect="true"
      :showDate="true"
      :showTotal="true"
      :baseCrudKey="baseCrudKey"
      :createNewItem="newItemDialog"
      :selectedItems="selectedItems"
      :itemTotal="selectedItemsAmount"
      :defaultSortBy="sortBy"
      :uploadFileParams="uploadFileParams"
      :loadingData="gridLoading.flag"
      :loadingText="gridLoading.text"
      toolbarTitle="Mortgage Tax"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtTransaction"
      @deleteItem="deleteTransaction"
      @emitSelectedItem="emitSelectedItem"
      @activateItem="activateTransaction"
    >
      <template v-slot:[`editedItem`]="{}">
        <TransactionEditedItem
          :baseCrudKey="baseCrudKey"
          :attachmentConstants="attachmentConstants"
          :cmnEnums="cmnEnums"
          @emitEditBatchPaymntItem="handleBatchPaymntDetailsEditItem"
          @editItem="editItem"
        />
      </template>
      <template v-slot:[`additionalActions`]="{}">
        <v-row>
          <v-btn
            id="mtgTransPrintFormatBtn"
            class="mtg-trans-print-format-btn"
            text color="blue darken-1"
            @click="mtgPrintReceiptFromDialogBox()">
          Print Receipt</v-btn>
        </v-row>
        <AuditDialog
          class="mtg-trans-audit-dialog"
          v-if="getActiveTransID"
          :identifier="getActiveTransID"
          activatorCaption="Audit History"
        />
        <UploadFile
          class="mtgtrans uploadFile"
          :bucketName="attachmentConstants.bucketName"
          :program="attachmentConstants.program"
          :parentObjectType="attachmentConstants.parentObjectType"
          :parentObjectId="getActiveTransID || ''"
          :itemIsSaved="!isNewItem"
        ><template v-slot:activator="{ activatorProps }">
        <v-btn
          color="primary"
          text
          @contextmenu.prevent="imagesRightClick(activatorProps.rightClick)"
          @click="imagesLeftClick(activatorProps.leftClick)"
        >
        {{activatorProps.caption}}
        </v-btn>
        </template>
       </UploadFile>
      </template>

        <!-- Only Super Admin Should Delete -->
      <template v-slot:[`canDeleteSlot`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="mtgTransListDeleteIcon"
              class="mx-2 mtg-translist-delete-icon" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              v-if="isSuperAdmin"
              :disabled="(selectedItems || []).length === 0"
              @click="deleteItems"
            >
              {{icons.mdiDelete}}
            </v-icon>
          </template>
            <span>Delete</span>
        </v-tooltip>
         <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>

      <template v-slot:[`additionalToolsAtRight`]="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn id="mtgTranListPrintReceiptBtn"
            class="mtg-translist-print-receipt-icon"
            depressed
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :disabled="printReceiptBtnValidation"
            @click="mtgPrintReceiptOutsideDialogBox()"
          >
          <v-icon left>
            {{ icons.mdiPrinter }}
          </v-icon>
          Receipt
          </v-btn>
          </template>
            <span>Print Receipt</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`bulkMarking`]="{}"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <transactionNumber
              v-if="forceReloadDetails"
              :cmnBaseCrudKey="baseCrudKey"
              :cmnEnums="cmnEnums"
              :closeDialogBox="closeDialogBox"
              :criteriaStateFY="criteriaState.fiscalYear"
              :selectedItemsAmount="selectedItemsAmount"
              :selectedItems="selectedItems"
              :isNewItem="isNewBatchPaymentTransaction"
              :batchPaymentDetailsEditItem="transNoObject"
              @settingDialogVal="settingDialogVal"
              @transactionNumberObj="transactionNumberObj"
              @deleteBatchPaymentDetails="deleteBatchPaymentDetails"
              />
            <v-btn v-bind="attrs" v-on="on"
            class="mx-2" color="primary"
            fab icon small
            :disabled="(selectedItems || []).length === 0"
            @click="openComponent"
            >
              <v-icon>
                {{icons.mdiAlphaBCircle}}
              </v-icon>
            </v-btn>
          </template>
           <span>Batch Pay</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
    </CRUDList>
  </div>
</template>

<script>
import {
  mdiDelete,
  mdiPrinter,
  mdiAlphaBCircle,
} from '@mdi/js';
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import TransactionCriteria from './TransactionCriteria.vue';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import TransactionEditedItem from './TransactionEditedItem.vue';
import transactionNumber from '../../common/transactionNumber/transactionNumber.vue';
import UploadFile from '../../common/UploadFile.vue';
import AuditDialog from '../../common/audit/AuditDialog.vue';
import {
  calculateFiscalYears,
  toFiscalYear,
  updateDateFormat,
} from '../../../util/shared/tmc-global';
import {
  amountToNumber,
  isEmptyObject,
  // defaultOverlayConfig,
  defaultGridLoading,
  manageGridLoading,
  extractId,
} from '../../../util/shared/vue-global';
import { clients } from '../../../util/clients';
import { mtgAllPaymentTypeObj, updateDescription } from './MtgTransactionHelper';
import { getWorkstationNumber } from '../../../util/workstation-util';
import MtgTransactionList from './MtgTransactionList.mixin';
import { authRoles } from '../../../router/roleGroups';
import { isAuthorised } from '../../../store/auth/token';

const { backendRest } = clients.direct;

const getHeaders = () => {
  const headers = [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, formatter: 'file',
    },
    { text: 'Date', value: 'businessDate' },
    { text: 'Rec#', value: 'receiptNumber' },
    { text: 'Wkst', value: 'wkst' },
    { text: 'Mortgagor', value: 'mortgagor' },
    { text: 'Received Of', value: 'receivedOf' },
    { text: 'Rate', value: 'rateOfTax' },
    {
      text: 'Mortgage Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
    {
      text: 'Mortgage Tax', value: 'mortgageTax', formatter: 'amount', align: 'right',
    },
    { text: 'Fee', value: 'fee' },
    { text: 'Term(Yrs)', value: 'termYear' },
  ];
  return headers;
};

const bankCrudKey = 'sharedBank';

export default {
  mixins: [MtgTransactionList],
  data: () => ({
    baseCrudKey: 'mtgTransaction',
    headers: [],
    dateForCriteria: ['fromBusinessDate', 'toBusinessDate'],
    activeTransItem: undefined,
    selectedComponent: null,
    cmnBaseCrudKey: 'mtgPaymentDetailForTransactionNumber',
    closeDialogBox: false,
    forceReloadDetails: false,
    transNoObject: {},
    activeEditedItem: {},
    attachmentConstants: {
      bucketName: 'mtg',
      program: 'mtg',
      parentObjectType: 'mtgTransaction',
    },
    uploadFileParams: {
      bucketName: 'mtg',
      program: 'mtg',
      parentObjectType: 'mtgTransaction',
    },
    uploadFileClickParams: {
      leftClickFlag: false,
      leftClickDef: undefined,
      rightClickFlag: false,
      rightClickDef: undefined,
    },
    icons: {
      mdiDelete,
      mdiPrinter,
      mdiAlphaBCircle,
    },
    gridLoading: defaultGridLoading(),
    sortBy: [
      {
        column: 'businessDate',
        direction: 'desc',
      },
      {
        column: 'receiptNumber',
        direction: 'desc',
      },
    ],
    isNewBatchPaymentTransaction: false,
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.initializeMtgFiscalYears();
  },
  components: {
    CRUDList,
    TransactionCriteria,
    TransactionEditedItem,
    UploadFile,
    AuditDialog,
    transactionNumber,
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      mtgRegYears: (state) => state.mtg.mtgTransaction.regYears,
      storedSelectedItems: (state) => state.mtg.mtgTransaction.selectedItems,
      nextReceiptNumber: (state) => state.mtg.mtgTransaction.nextReceiptNumber,
      mtgSettingItem: (state) => state.SystemConfig.mtgSettingItem,
      businessDateRange: (state) => state.mtg.mtgTransaction.businessDateRange,
      cmnEnums(state) {
        return this.mapBankToPaymentType(state.enums);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    getActiveTransID() {
      return this.activeTransItem && this.activeTransItem._id;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
    fiscalYearList() {
      const registerdYears = (this.mtgRegYears || {});
      const allowAll = true;
      return calculateFiscalYears(registerdYears, allowAll);
    },
    selectedItemsAmount() {
      const amountSum = (this.selectedItems || []).reduce((acc, obj) => acc
        + amountToNumber(obj.amount), 0);
      return amountSum;
    },
    printReceiptBtnValidation() {
      return (this.selectedItems || []).length === 0
       || (this.selectedItems || []).length > 1;
    },
    isSuperAdmin() {
      return isAuthorised(authRoles.SuperAdmin);
    },
  },
  methods: {
    manageGridLoading,
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions(['reAuth', 'flashError']),
    ...mapActions(['flashInfo', 'flashSuccess']),
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapActions('mtg/mtgTransaction', [
      'loadMtgTransaction',
      'upsertMtgTrans',
      'deleteMtgTransaction',
      'loadFiscalRegYears',
      'loadLastReceiptNo',
      'loadLastTransactionNo',
      'loadBusinessDateRange',
    ]),
    ...mapMutations('files', [
      'setFiles',
    ]),
    ...mapMutations('mtg/mtgTransaction', [
      'setSelectedItems',
      'setReceiptPulse',
      'setReceiptPolling',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
      'setIndex',
      'setIsVisible',

    ]),
    ...mapActions('common/cmnWhom', [
      'loadCmnWhom',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
      'setIndex',
    ]),
    ...mapMutations('common/cmnTransNumber', [
      'setTransNoPolling',
      'setTransNoPulse',
    ]),
    handleBatchPaymntDetailsEditItem(item) {
      this.transNoObject = JSON.parse(JSON.stringify(item));
      this.isNewBatchPaymentTransaction = false;
      this.closeDialogBox = true;
      this.forceReloadDetails = true;
      return this.transNoObject;
    },
    handleLoadCRUDList(criteriaArgs = {}) {
      const { baseCrudKey } = this;
      const criteria = this.updateCriteriaForReq();
      manageGridLoading(this, true, 'Searching...');
      this.loadMtgTransaction({
        criteria,
        baseCrudKey,
        criteriaArgs,
        postProcessTransaction: this.postProccessData,
      }).then(() => {
        this.setDefaultSelectedItems(criteriaArgs);
      }).catch((e) => {
        console.warn(e);
        this.setDefaultSelectedItems(criteriaArgs);
      }).finally(() => {
        manageGridLoading(this, false);
      });
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
      this.criteriaState = { ...this.criteriaState, ...this.handleQuery() };
    },
    async initializeMtgFiscalYears() {
      await this.loadFiscalRegYears({ baseCrudKey: this.baseCrudKey });
      const regYears = this.fiscalYearList || [];
      if ((regYears || []).length > 0) {
        this.criteriaState.fiscalYear = (regYears[regYears.length - 1] || {}).year;
      }
      this.handleLoadCRUDList();
      this.loadLists(this.criteriaState.fiscalYear);
    },
    updateCriteriaForReq() {
      const updatedCriteria = updateDateFormat(this.criteriaState, this.dateForCriteria);
      return updatedCriteria;
    },
    async upsrtTransaction(item) {
      /* For new item check wkst, and if it is not avaible exit early with message */
      if (this.isNewItem && !item.wkst) {
        this.flashError('Error: While upserting receipt, workstation number not exist!');
        return {};
      }
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      delete updatedItem.hasFiles;
      delete updatedItem.crudRowCssAppend;
      updatedItem.amount = amountToNumber(updatedItem.amount);
      updatedItem.fee = amountToNumber(updatedItem.fee);
      updatedItem.mortgageTax = amountToNumber(updatedItem.mortgageTax);
      updatedItem.rateOfTax = amountToNumber(updatedItem.rateOfTax);
      updatedItem.termYear = amountToNumber(updatedItem.termYear);
      updatedItem.mortgageAmount = amountToNumber(updatedItem.mortgageAmount);
      if (updatedItem.transactionNumberObject) {
        updatedItem.transactionNumberObject = extractId(updatedItem.transactionNumberObject);
      }
      if ((updatedItem.paymentDetails || []).length > 0) {
        updatedItem.paymentDetails = updatedItem.paymentDetails.map((element) => {
          const newUpdatedElement = this.buildPaymentItem(element);
          return {
            ...newUpdatedElement,
          };
        });
      }
      /*
        Trying to reduce the chance of skipping receipt number,
        stopping polling just before upserting
      */
      this.setReceiptPolling(undefined);
      this.setTransNoPolling(undefined);
      const response = await this.upsertMtgTrans({
        item: updatedItem,
        baseCrudKey,
        postProcessTransaction: this.postProccessData,
      });
      if (!isEmptyObject(response)) {
        this.updateActiveTransItem(response);
      }
      this.setDefaultSelectedItems();
      return response;
    },
    buildPaymentItem(element) {
      if (element.paymentType) {
        const amount = amountToNumber(element.amount);
        const updatedElement = {
          paymentType: element.paymentType,
          amount,
        };
        if (element.paymentType === 'CASH') {
          updatedElement.cashReceived = amountToNumber(element.cashReceived);
          updatedElement.cashChange = amountToNumber(element.cashChange);
        }
        if (element.paymentType === 'CREDIT') {
          updatedElement.creditType = element.creditType;
        }
        if (element.paymentType === 'CHECK') {
          updatedElement.checkNumber = element.checkNumber;
          updatedElement.checkPaidBy = element.checkPaidBy;
        }
        if (element.paymentType === 'EFT') {
          updatedElement.eftType = element.eftType;
        }
        return { ...updatedElement };
      }
      return { ...element };
    },
    deleteTransaction(item) {
      const { baseCrudKey } = this;
      this.deleteMtgTransaction({ item, baseCrudKey }).then(() => {
        this.selectedItems = [];
      }).catch((e) => {
        console.warn(e);
        this.selectedItems = [];
      });
    },
    async deleteItems() {
      if (window.confirm(`CAREFUL! Are you sure you want to DELETE ${this.selectedItems.length} record/s?`)) {
        for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
          const item = this.selectedItems[i];
          this.deleteTransaction(item);
        }
      }
      this.selectedItems = [];
    },
    mapBankToPaymentType(stateArr) {
      const mtgSettingObj = { ...this.mtgSettingItem } || {};
      const bankArr = this.items(bankCrudKey) || [];
      const paymentTypeEnums = updateDescription(stateArr.mtgPaymentTypeEnum,
        mtgSettingObj, bankArr);
      const eftTypeEnums = updateDescription(stateArr.mtgEFTTypeEnum, mtgSettingObj, bankArr);
      const mtgPaymentObj = {
        cmnPaymentTypes: paymentTypeEnums,
        cmnEFTTypes: eftTypeEnums,
        cmnCardTypes: stateArr.mtgCCardTypeEnum || [],
      };
      return mtgPaymentObj;
    },
    async loadLists(fiscalYear) {
      const currentFiscalYear = fiscalYear || toFiscalYear(new Date(this.todaysDate));
      await this.loadConfig({ requiredScope: 'mtgConfig' });
      this.loadCmnWhom({
        criteria: {
          fiscalYear: currentFiscalYear,
        },
        baseCrudKey: 'mtgWhom',
      });
      this.loadBankData();
    },
    loadBankData() {
      const mtgSettingObj = { ...this.mtgSettingItem } || {};
      const paymentTypeIds = [];
      const enumFieldsArr = Object.values(mtgAllPaymentTypeObj);
      for (const enumValue of enumFieldsArr) {
        if (mtgSettingObj[enumValue]) {
          paymentTypeIds.push(mtgSettingObj[enumValue]);
        }
      }
      this.loadBanks({
        criteria: {
          _ids: paymentTypeIds,
          notEmptyAltKey: true,
          limit: 0,
        },
        baseCrudKey: bankCrudKey,
      });
    },
    newItemDialog() {
      this.setFiles([]);
      let newItem = {};
      newItem = {
        businessDate: this.todaysDate,
        fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        receiptNumber: null,
        receivedOf: '',
        mortgagor: '',
        mortgagee: '',
        // isVoid: '',
        amount: 0.00,
        remark: '',
        dateOfMortgage: null,
        mortgageDueDate: null,
        termYear: 0,
        mortgageAmount: 0.00,
        rateOfTax: 0.00,
        mortgageTax: 0.00,
        fee: 5.00,
        property: '',
        sectionLot: '',
        township: '',
        rangeBlk: '',
        wkst: null,
        transactionNumber: null,
      };
      this.loadLastReceiptNo({ fiscalYear: newItem.fiscalYear });
      newItem.wkst = getWorkstationNumber();
      newItem.amount = newItem.fee + newItem.mortgageTax;
      if (this.mtgSettingItem && this.mtgSettingItem.usedLastBusinessDate) {
        this.loadBusinessDateRange().then(() => {
          newItem.businessDate = new Date(this.businessDateRange.max) > new Date()
            ? this.businessDateRange.max : this.todaysDate;
        }).catch((e) => {
          console.warn(e);
          newItem.businessDate = this.todaysDate;
        });
      }
      return newItem;
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    setDefaultSelectedItems(criteriaArgs = {}) {
      if (criteriaArgs.searchMode === 'wildcard') {
        this.$nextTick(() => {
          this.selectedItems = [...this.selectedItems];
        });
      } else {
        this.$nextTick(() => {
          this.selectedItems = [];
        });
      }
    },
    activateTransaction(val) {
      this.activeTransItem = val;
    },
    getChuckID() {
      return ((this.$route || {}).query || {}).chunkID;
    },
    async imagesLeftClick(leftClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.leftClickFlag = true;
        this.uploadFileClickParams.leftClickDef = leftClick;
      } else {
        leftClick();
      }
    },
    async imagesRightClick(rightClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.rightClickFlag = true;
        this.uploadFileClickParams.rightClickDef = rightClick;
      } else {
        rightClick();
      }
    },
    settingFields(res) {
      this.activeTransItem = res;
      this.setItem([this.baseCrudKey, res]);
      this.setIndex([this.baseCrudKey, 0]);
      this.setReceiptPolling(undefined);
      this.setTransNoPolling(undefined);
    },
    updateActiveTransItem(res) {
      if (this.uploadFileClickParams.leftClickFlag) {
        this.settingFields(res);
        this.$nextTick(() => {
          this.imagesLeftClick(this.uploadFileClickParams.leftClickDef);
          this.uploadFileClickParams.leftClickFlag = false;
          this.uploadFileClickParams.leftClickDef = undefined;
        });
      } else if (this.uploadFileClickParams.rightClickFlag) {
        this.settingFields(res);
        this.$nextTick(() => {
          this.imagesRightClick(this.uploadFileClickParams.rightClickDef);
          this.uploadFileClickParams.rightClickFlag = false;
          this.uploadFileClickParams.rightClickDef = undefined;
        });
      }
      return false;
    },
    handleQuery() {
      const chunkID = this.getChuckID();
      const paramsCriteria = {};
      if (chunkID) {
        paramsCriteria.wildcard = chunkID;
        // for bulk import set also set FY criteria (all years)
        paramsCriteria.fiscalYear = 0;
      }
      return paramsCriteria;
    },
    async upsertMtgData() {
      const result = await this.upsrtTransaction(this.activeEditedItem);
      this.settingFields(result);
      return result._id;
    },
    async mtgPrintReceiptFromDialogBox() {
      const isValidForm = this.$refs.crudList.$refs.crudDialog.validateForm();
      if (isValidForm) {
        const mtgTransId = await this.upsertMtgData();
        this.printReceipt(mtgTransId);
      }
    },
    async mtgPrintReceiptOutsideDialogBox() {
      const mtgTransId = this.selectedItems.map((t) => t._id).join(',');
      this.printReceipt(mtgTransId);
    },
    async printReceipt(mtgTransId) {
      const { jwt } = await this.reAuth();
      const url = `${backendRest.defaults.baseURL}/mtgPrintReceiptFormat?mtgTransId=${mtgTransId}&token=${jwt}`;
      window.open(url, '_blank');
    },
    requiredTransNoObject() {
      const updatedArray = [];
      for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
        const item = { ...this.selectedItems[i] };
        if (this.isContainingDetails(item)) {
          updatedArray.push(item);
        }
      }
      return updatedArray;
    },
    openComponent() {
      const transObj = this.requiredTransNoObject();
      this.isNewBatchPaymentTransaction = true;
      if (transObj.length === this.selectedItems.length) {
        if (window.confirm(`Are you sure want to Batch Pay ${(this.selectedItems || []).length} record/s?`)) {
          // this.initBatchPaymentObject = {};
          this.closeDialogBox = true;
          this.forceReloadDetails = true;
        }
      } else {
        this.flashInfo('One or more transactions already contain Transaction Number.');
        this.selectedItems = [];
      }
    },
    settingDialogVal(value) {
      this.closeDialogBox = value;
      // removing from dom
      this.forceReloadDetails = false;
      this.transNoObject = {};
    },
    async transactionNumberObj(transNumObj) {
      if (this.isNewBatchPaymentTransaction) {
        this.upsertBatchPaymentDetails(transNumObj);
      } else {
        this.handleTransactionsAfterBatchPayment([], { operation: 'update' });
      }
      this.selectedItems = [];
    },
    customCloseDialogBoxUtility() {
      this.setIsVisible([this.baseCrudKey, false]);
      this.$nextTick(() => {
        this.editedItem = { ...this.newItemDialog };
      });
    },
    editItem(val) {
      this.activeEditedItem = val;
    },
  },
};
</script>
