export const mtgAllPaymentTypeObj = {
  SIMPLIFILE: 'eftPayementTypeSimplifile',
  OTC: 'eftPayementTypeOtc',
  IRS: 'eftPayementTypeIrs',
  FORTE: 'eftPayementTypeForte',
  CSC: 'eftPayementTypeCsc',
  OTHER: 'eftPayementTypeOther',
  CASH: 'cashAndCheckDepositBank',
  CHECK: 'cashAndCheckDepositBank',
  CREDIT: 'creditCardDepositBank',
};

export const updateDescription = (arr = [], settingObj, bankArr) => {
  const enumValuesArr = JSON.parse(JSON.stringify(arr));
  for (const item of enumValuesArr) {
    const keyName = item.name;
    const id = settingObj[mtgAllPaymentTypeObj[keyName]];
    const bankDetails = bankArr.find((bank) => bank._id.toString() === id);
    if (bankDetails) {
      item.description = `${item.description}   ${bankDetails.altKeyBase}`;
    }
  }
  return enumValuesArr;
};

export default {};
