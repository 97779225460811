<template>
 <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
    >
      <template v-slot:criteriaRows>
        <v-row>
        <v-col cols="3">
          <FySelect id="mtgCriteriaFYSelect"
            class="mtg-criteria-FY-select"
            :fyRange="fiscalYearRange"
            v-model="criteriaState.fiscalYear"
            @input="searchByCriteria();"
            />
        </v-col>
          <v-col cols="3">
            <v-select id="misc-criteria-isVoid"
              v-model="criteriaState.isVoid"
              :items="isVoidOptions"
              item-text="caption"
              item-value="value"
              label="Type"
              @change="searchByCriteria"
              >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="5">
          <v-text-field id="transactionWildCardText"
            class="mtg-criteria-wildCard-text"
            label="Wildcard search (receipt number #, amount, mortgagor, or received of)"
            v-model="criteriaState.wildcard"
            @input="wildcardSearchDelayed"
            autocomplete="off"
          ></v-text-field>
        </v-col>
          <v-col cols="3">
            <ClarionDateControl id="mtgCriteriaFromBuisnessDate"
              class="mtg-criteria-from-buisness-date"
              v-model="criteriaState.fromBusinessDate"
              :isRequired="false"
              :label="`From (Business Date)`"
              :isIsoDate="true"
              @blurred="searchByCriteria"
              />
          </v-col>
          <v-col cols="3">
            <ClarionDateControl id="mtgCriteriaToBuisnessDate"
              class="mtg-criteria-to-buisness-date"
              v-model="criteriaState.toBusinessDate"
              :isRequired="false"
              :isIsoDate="true"
              :label="`To`"
              @blurred="searchByCriteria"
              />
          </v-col>
        </v-row>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import FySelect from '../../common/FySelect.vue';
import {
  calculateFiscalYears,
} from '../../../util/shared/tmc-global';

export default {
  name: 'TransactionCriteria',
  components: {
    CRUDCriteria,
    ClarionDateControl,
    FySelect,
  },
  data: () => ({
    baseCrudKey: 'mtgTransaction',
    delayTimerId: undefined,
  }),
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.mtg.mtgTransaction.regYears || {};
        return calculateFiscalYears(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    isVoidOptions() {
      return [
        {
          value: null,
          caption: 'All',
        },
        {
          value: true,
          caption: 'Void',
        },
      ];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('mtg/mtgTransaction', [
      'loadMtgTransaction',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList(criteriaArgs = {}) {
      this.$emit('loadCRUDList', criteriaArgs);
    },
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        const criteriaArgs = { searchMode: 'wildcard' };
        await this.handleLoadCRUDList(criteriaArgs);
      }, 250);
    },
  },
};
</script>
