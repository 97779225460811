<template>
  <div>
   <v-container>
    <div v-show="isVisibleProp"></div>
    <v-row>
      <v-col cols="2">
        <v-text-field id="mtgTransItemFyText"
          class="mtg-trans-item-fy-text"
          label="Fiscal Year"
          v-model.number="editedItem.fiscalYear"
          type="number"
          :rules="fiscalYearValidation"
          :disabled="true"
        ></v-text-field>
      </v-col>
      <v-col col="3">
        <ClarionDateControl id="mtgTransItemBuisnessDate"
          class="mtg-trans-item-business-date"
          :isIsoDate="true"
          label="Business Date"
          v-model="editedItem.businessDate"
          :rules="reqdSelectValidation"
        />
      </v-col>
      <v-col col="3">
        <GetMaxReceiptNumber id="mtgTransItemMaxReceiptNo"
          class="mtg-trans-item-max-receipt-no"
          v-model.number="editedItem.receiptNumber"
          :fiscalYear="editedItem.fiscalYear"
          :isNewItem="isNewItem"
          fieldType="Receipt Number"
          :rules="reqdSelectValidation"
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete id="mtgTransItemReceivedOfComplete"
          class="mtg-trans-received-of"
          v-model="editedItem.receivedOf"
          label="Received Of"
          autofocus
          :items="receivedOf"
          @keydown="whomShortcutHandler"
          @update:list-index="whomUpdateSearchInputByNumber"
          @update:search-input="whomUpdateSearchInput"
          @focus="roundAmount(); $event.target.select()"
          @blur="autofillMortgagee"
        ></v-autocomplete>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="4">
        <v-textarea id="mtgTransItemMortgagorText"
          class="mtg-trans-item-mortgagor-text"
          label="Mortgagor"
          v-model="editedItem.mortgagor"
          rows="2"
        ></v-textarea>
      </v-col>
        <v-col cols="4">
        <v-textarea id="mtgTransItemMortgageeText"
          class="mtg-trans-item-mortgagee-text"
          label="Mortgagee"
          rows="2"
          v-model="editedItem.mortgagee"
          @focus="$event.target.select()"
        ></v-textarea>
        </v-col>
        <v-col cols="4">
        <v-textarea id="mtgTransItemPropertyText"
          class="mtg-trans-item-property-text"
          label="Property"
          rows="2"
          v-model="editedItem.property"
        ></v-textarea>
        </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemSectionLotText"
          class="mtg-trans-item-section-lot-text"
          label="Section/Lot"
          v-model="editedItem.sectionLot"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemTownshipText"
          class="mtg-trans-item-township-text"
          label="Township"
          v-model="editedItem.township"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemRangeBlkText"
          class="mtg-trans-item-range-blk-text"
          label="Range/Blk"
          v-model="editedItem.rangeBlk"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <ClarionDateControl id="mtgTransItemMortgageDate"
          class="mtg-trans-item-mortgage-date"
          label="Date of Mortgage"
          v-model="editedItem.dateOfMortgage"
          :isIsoDate="true"
          @blurred="calculateDateDifference"
        />
      </v-col>
      <v-col cols="4">
        <ClarionDateControl id="mtgTransItemMortgageDueDate"
          class="mtg-trans-item-mortgage-due-date"
          label="Mortgage Due"
          v-model="editedItem.mortgageDueDate"
          :isIsoDate="true"
          @blurred="calculateDateDifference"
          :rules="termYearValidation"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemTermYrsText"
          class="mtg-trans-item-term-text"
          label="Term(Yrs)"
          v-model="editedItem.termYear"
          @blur="recalculateROT"
          @keyup="recalculateROT"
        ></v-text-field>
      </v-col>
    </v-row>
      <v-row>
        <v-col cols="4">
        <v-text-field id="mtgTransItemMortgageAmtText"
          class="mtg-trans-item-mortgage-amt-text"
          label="Mortgage Amount"
          v-model="editedItem.mortgageAmount"
          @blur="recalculateMortgageTax(); roundAmount()"
          @keyup="recalculateMortgageTax"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemRateOfTaxText"
          class="mtg-trans-item-rate-of-tax-text"
          label="Rate of Tax"
          v-model="editedItem.rateOfTax"
          @blur="recalculateMortgageTax"
          @keyup="recalculateMortgageTax"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemMortgageTaxText"
          class="mtg-trans-item-mortgage-tax-text"
          label="Mortgage Tax"
          v-model="editedItem.mortgageTax"
          @blur="recalculateAmount"
          @keyup="recalculateAmount"
        ></v-text-field>
      </v-col>
      </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field id="mtgTransItemFeeText"
          class="mtg-trans-item-fee-Text"
          label="Fee"
          v-model="editedItem.fee"
          @blur="recalculateAmount"
          @keyup="recalculateAmount"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field id="mtgTransItemTotalText"
          class="mtg-trans-item-total-Text"
          label="Total"
          v-model="editedItem.amount"
          :rules="amountValidation"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="4">
          <v-text-field id="mtgTransItemWkstText"
            class="mtg-trans-item-wkst-Text"
            label="Work Station"
            :value="editedItem.wkst"
            type="string"
            :disabled="true"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-textarea id="mtgTransItemRemarkText"
          class="mtg-trans-item-remark-Text"
          label="Remarks"
          rows="2"
          v-model="editedItem.remark"
        ></v-textarea>
      </v-col>
        <v-col cols="2">
          <v-checkbox id="mtgTransItemVoidCheckbox"
            class="mtg-trans-item-void-checkbox"
            v-model="editedItem.isVoid"
            label="Void"
            :rules="voidAndBatchPaymentValidation"
            @change="isVoidChecked"
          ></v-checkbox>
        </v-col>
      <v-col cols="3">
        <GetMaxTransNumber id="mtgTransItemTransNoText"
          v-model.number="editedItem.transactionNumber"
          class="mtg-trans-item-trans-no-Text"
          fieldType="Transaction Number"
          :fiscalYear="editedItem.fiscalYear"
          :cmnBaseCrudKey="baseCrudKey"
          :loadLastTransactionNo="loadLastTransactionNo"
          :rules="reqdSelectValidation"
        />
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PaymentDetails id="mtgTransItemPaymentDetails"
          class="mtg-trans-item-payment-details"
          v-model="editedItem.paymentDetails"
          toolbarTitle="Payment Details"
          :amount="editedItem.amount"
          :whomString="editedItem.receivedOf"
          :cmnEnums="cmnEnums"
          :defaultForteConfig="defaultForteConfig"
          :isDialogBoxVisible="isVisibleProp"
          :isTotal="true"
          :paymentDetailsFlags="{ isCallForNormalPayment:true }"
          :transactionId="transactionId"
          :showPaymentHeader="true"
          @forteResponse="mtgForteResponse"
          @totalRemainingPaymentAmount="totalRemainingPaymentAmount"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="transactionId"
    >
      <v-col>
        <PaymentDetails id="mtgTransItemPaymentDetails"
          class="mtg-trans-item-payment-details"
          toolbarTitle="Batch Payment Details"
          :isReadOnly="true"
          :value="transNumValue"
          :whomString="editedItem.receivedOf"
          :cmnEnums="cmnEnums"
          :isDialogBoxVisible="isVisibleProp"
          :paymentDetailsFlags="{ isCallForBatchPayment:true }"
          :isTotal="false"
          :transactionId="transactionId"
          @emitEditBatchPaymntItem="emitEditBatchPaymntItem"
        />
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
import {
  amountFormat,
  amountToNumber,
  onFocusAutocomplete,
  checkStringEmptiness,
} from '../../../util/shared/vue-global';

import ClarionDateControl from '../../common/ClarionDateControl.vue';
import PaymentDetails from '../../common/paymentDetails/PaymentDetails.vue';
import GetMaxReceiptNumber from './GetMaxReceiptNumber.vue';
import {
  calculateRateOfTax, calculateMtgTax, calculateTotalAmt, calculateTermYear,
} from './mtg-tax-calculator.util';
import GetMaxTransNumber from '../../common/transactionNumber/GetMaxTransNumber.vue';

const amountFields = [
  'amount',
  'fee',
  'mortgageTax',
  'mortgageAmount',
];
export default {
  name: 'MtgTaxEditedItem',
  components: {
    ClarionDateControl,
    PaymentDetails,
    GetMaxReceiptNumber,
    GetMaxTransNumber,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'mtgTranspayment-dialog',
    },
    attachmentConstants: {
      type: Object,
      default: () => {},
    },
    cmnEnums: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    totalRemainingPayAmt: null,
    whomLastSearched: undefined,
    forceReloadDetails: true,
    whomListCrudKey: 'mtgWhom',
    defaultForteConfig: {
      configScope: 'mtgConfig',
      isRequiredForte: true,
    },
  }),
  created() {
    this.loadConfig({ requiredScope: 'mtgConfig' });
  },
  watch: {
    editedItem: {
      handler(val) {
        this.$emit('editItem', val);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
      mtgSettingItem: (state) => state.SystemConfig.mtgSettingItem || {},
    }),
    ...mapState({
      nextReceiptNumber: (state) => state.mtg.mtgTransaction.nextReceiptNumber,
    }),
    ...mapState({
      nextTransNumber: (state) => state.common.cmnTransNumber.nextTransNumber,
      transNoPulse: (state) => state.common.cmnTransNumber.transNoPulse,
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    receivedOf() {
      const mtgWhomsList = (this.items(this.whomListCrudKey) || []).map(
        (r) => r.description || '',
      );
      mtgWhomsList.push(this.editedItem.receivedOf || '');
      return mtgWhomsList;
    },
    amountValidation() {
      return [
        (this.isPaymentAmtMatched) || ('Mismatched Payment Amount'),
      ];
    },
    isPaymentAmtMatched() {
      return (this.editedItem.paymentDetails || []).length === 0 || this.totalRemainingPayAmt === 0;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (isVisible) {
        if (this.editedItem._id) {
          this.loadFiles();
        }
        if (this.isNewItem) {
          this.determineReceiptNo();
          this.determineTransactionNo();
        }
      } else {
        this.setReceiptPolling(undefined);
        this.setTransNoPolling(undefined);
      }
      return isVisible;
    },
    transNumValue() {
      const copiedObj = JSON.parse(JSON.stringify((this.editedItem.transactionNumberObject || {})
        .paymentDetails || []));
      return copiedObj;
    },
    transactionId() {
      return this.editedItem.transactionNumberObject
      && this.editedItem.transactionNumberObject._id;
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    termYearValidation() {
      return [
        (value) => {
          if (value && this.editedItem.dateOfMortgage) {
            if (new Date(value) < new Date(this.editedItem.dateOfMortgage)) {
              return 'Mortgage Due Date must be greated than Date of Mortgage';
            }
          }
          return true;
        },
      ];
    },
    fiscalYearValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => !Number.isNaN(parseFloat(value)) || 'Numeric required',
        (value) => parseFloat(value) > 0 || 'Must be a positive year',
        (value) => (value || '').toString().length === 4 || 'Fiscal year must be 4 digits',
        (value) => parseFloat(value) < 3000 || 'Must be a reasonable year',
      ];
    },
    fiscalYearLength() {
      return (this.editedItem.fiscalYear || '').toString().length;
    },
    voidAndBatchPaymentValidation() {
      return [
        (value) => {
          if (value && !checkStringEmptiness(this.transactionId)) {
            return 'Before making void, Delete Batch-Payments';
          }
          return true;
        },
      ];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    ...mapActions('files', [
      'loadAttachedFiles',
    ]),
    ...mapActions([
      'flashInfo',
    ]),
    ...mapActions('common/cmnWhom', [
      'loadCmnWhom',
      'upsrtCmnWhom',
    ]),
    ...mapActions('mtg/mtgTransaction', [
      'loadLastReceiptNo',
      'loadLastTransactionNo',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    ...mapMutations('mtg/mtgTransaction', [
      'setReceiptPolling',
      'setReceiptPulse',
    ]),
    ...mapMutations('common/cmnTransNumber', [
      'setTransNoPolling',
      'setTransNoPulse',
    ]),
    emitEditBatchPaymntItem() {
      this.$emit('emitEditBatchPaymntItem', this.editedItem.transactionNumberObject);
    },
    onFocusAutocomplete,
    whomUpdateSearchInput(value) {
      this.whomLastSearched = value;
    },
    whomUpdateSearchInputByNumber(number) {
      this.whomLastSearched = this.receivedOf[number];
    },
    async whomShortcutHandler(e) {
      if (e.key === 'Tab') {
        const { receivedOf } = this.editedItem;
        const { whomLastSearched } = this;
        const fields = {
          _id: '',
          description: '',
          fiscalYear: '',
        };
        const matchWhoms = this.receivedOf.filter((w) => w === receivedOf);
        const matchWhomLastSearched = this.receivedOf.filter(
          (w) => w === whomLastSearched,
        );
        if (matchWhoms.length > 0 || whomLastSearched) {
          const matchWhom = matchWhoms[0] ? matchWhoms[0] : '';
          if (
            (matchWhom || '').toLowerCase()
              !== (whomLastSearched || '').toLowerCase()
            && matchWhomLastSearched.length === 0
          ) {
            this.flashInfo('Creating a new Whom entry.');
            // create a new record to match the description typed
            const item = {
              description: whomLastSearched,
              fiscalYear: this.editedItem.fiscalYear,
            };
            await this.upsrtCmnWhom({
              item,
              fields,
              baseCrudKey: this.whomListCrudKey,
            });
            await this.loadCmnWhom({
              criteria: {},
              fields,
              baseCrudKey: this.whomListCrudKey,
            });
          }
          this.editedItem.receivedOf = whomLastSearched;
        }
        this.handlingTabOrder(e);
      }
    },
    totalRemainingPaymentAmount(value) {
      this.totalRemainingPayAmt = amountToNumber(value);
      return value;
    },
    roundAmount() {
      return amountFields.forEach((f) => {
        this.editedItem[f] = amountFormat(amountToNumber(this.editedItem[f]));
      });
    },
    determineReceiptNo() {
      if (this.isNewItem) {
        if (this.fiscalYearLength !== 4) {
          this.setReceiptPolling(undefined);
        } else {
          const mtgTransPolling = setInterval(
            this.getRecNo,
            1200,
          );
          this.setReceiptPolling(mtgTransPolling);
        }
      }
    },
    getRecNo() {
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fiscalYear)) && this.isNewItem) {
        this.determineNextReceiptNo(
          {
            fiscalYear,
          },
        );
      }
    },
    determineNextReceiptNo({ fiscalYear }) {
      const loadReceiptNoPromise = this.loadLastReceiptNo(
        { fiscalYear },
      );
      loadReceiptNoPromise.then(() => {
        let animate = false;
        if (this.isNewItem) {
          animate = true;
        }
        this.editedItem.receiptNumber = this.nextReceiptNumber;
        if (animate) {
          this.$nextTick(() => {
            this.setReceiptPulse(true);
          });
        } else {
          this.setReceiptPulse(false);
        }
      });
    },
    recalculateROT() {
      const { termYear, mortgageAmount, fee } = this.editedItem;
      const calcRateOfTax = calculateRateOfTax({ termYear });
      const calcMortgageTax = calculateMtgTax({ mortgageAmount, rateOfTax: calcRateOfTax });
      const amount = calculateTotalAmt({ mortgageTax: calcMortgageTax, fee });

      this.editedItem.rateOfTax = calcRateOfTax;
      this.editedItem.mortgageTax = calcMortgageTax;
      if (!this.editedItem.isVoid) {
        this.editedItem.amount = amount;
      }
    },
    recalculateMortgageTax() {
      const { rateOfTax, mortgageAmount, fee } = this.editedItem;
      const calcMortgageTax = calculateMtgTax({ mortgageAmount, rateOfTax });
      const amount = calculateTotalAmt({ mortgageTax: calcMortgageTax, fee });

      this.editedItem.mortgageTax = calcMortgageTax;
      if (!this.editedItem.isVoid) {
        this.editedItem.amount = amount;
      }
    },
    recalculateAmount() {
      const { mortgageTax, fee } = this.editedItem;
      const amount = calculateTotalAmt({ mortgageTax, fee });

      if (!this.editedItem.isVoid) {
        this.editedItem.amount = amount;
      }
    },
    loadFiles() {
      this.loadAttachedFiles({
        bucketName: this.attachmentConstants.bucketName,
        program: this.attachmentConstants.program,
        parentObjectType: this.attachmentConstants.parentObjectType,
        parentObjectId: this.editedItem._id,
      });
    },
    isVoidChecked() {
      if (this.editedItem.isVoid) {
        this.editedItem.fee = 0;
        this.editedItem.mortgageTax = 0;
        this.editedItem.amount = 0;
        this.editedItem.paymentDetails = [];
      }
    },
    calculateDateDifference() {
      if (this.editedItem.dateOfMortgage && this.editedItem.mortgageDueDate) {
        const dateOfMortgage = new Date(this.editedItem.dateOfMortgage);
        const mortgageDueDate = new Date(this.editedItem.mortgageDueDate);
        const termYear = calculateTermYear({ dateOfMortgage, mortgageDueDate });
        if (termYear) {
          this.editedItem.termYear = termYear;
        }
      }
    },
    autofillMortgagee() {
      if (!this.mtgSettingItem.disableAutofillMortgagee) {
        if (this.editedItem.receivedOf !== '' && this.editedItem.mortgagee === '') {
          this.editedItem.mortgagee = this.editedItem.receivedOf;
        }
      } else {
        // do nothing
      }
      return this.editedItem;
    },
    mtgForteResponse(res) {
      this.editedItem.remark += res;
    },
    determineTransactionNo() {
      if (this.isNewItem) {
        if (this.fiscalYearLength !== 4) {
          this.setTransNoPolling(undefined);
        } else {
          const transNoPolling = setInterval(
            this.getTransNo,
            1200,
          );
          this.setTransNoPolling(transNoPolling);
        }
      }
    },
    getTransNo() {
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fiscalYear)) && this.isNewItem) {
        this.determineNextTransNo(
          {
            fiscalYear,
          },
        );
      }
    },
    determineNextTransNo({ fiscalYear }) {
      const loadTransNoPromise = this.loadLastTransactionNo(
        { fiscalYear },
      );
      loadTransNoPromise.then(() => {
        const animate = true;
        this.editedItem.transactionNumber = this.nextTransNumber;
        if (animate) {
          this.$nextTick(() => {
            this.setTransNoPulse(true);
          });
        } else {
          this.setTransNoPulse(false);
        }
      });
    },
    handlingTabOrder(e) {
      if (this.editedItem && this.editedItem.receivedOf) {
        e.preventDefault();
        document.getElementById('mtgTransItemMortgagorText').focus();
        this.autofillMortgagee();
      }
    },
  },
};
</script>
