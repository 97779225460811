<template>
  <v-container>
    <h3>Mortgage Tax</h3>
    <TransactionList/>
  </v-container>
</template>

<script>

import TransactionList from '@/components/Mtg/Transaction/TransactionList.vue';

export default {
  name: 'MortgageTax',
  components: {
    TransactionList,
  },
};
</script>
